import { render, staticRenderFns } from "./RetrievePassword.vue?vue&type=template&id=9af0e37e&scoped=true"
import script from "./RetrievePassword.vue?vue&type=script&lang=ts"
export * from "./RetrievePassword.vue?vue&type=script&lang=ts"
import style0 from "./RetrievePassword.vue?vue&type=style&index=0&id=9af0e37e&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9af0e37e",
  null
  
)

export default component.exports