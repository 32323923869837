
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CountDown from "./components/mixin/countDown";
interface Retrieve {
  loginId: string;
  code: string;
  password: string;
  verifyPassword: string;
}

@Component({
  name: "RetrievePassword",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class RetrievePassword extends mixins(CountDown) {
  // 注册
  public formData: Retrieve = {
    loginId: "",
    code: "",
    password: "",
    verifyPassword: "",
  };
  // 验证规则
  public rules: any = {
    loginId: [
      { required: true, message: "请输入邮箱或手机号", trigger: "blur" },
    ],
    code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    password: [
      { required: true, message: "请设置6-12位登录密码", trigger: "blur" },
      {
        pattern: /^[^\u4e00-\u9fa5]{6,12}$/,
        message: "密码请设置6-12位的非中文字符",
      },
    ],
    verifyPassword: [
      { required: true, message: "密码6-12位，区分大小写", trigger: "blur" },
      { validator: this.verifyPasswordCheck },
    ],
  };

  // 获取验证码
  queryCode(): void {
    if (this.formData.loginId) {
      let params = {
        loginId: this.formData.loginId,
        modelNumber: "003",
      };
      this.isQueryCode = true;
      this.againQueryTime();
      this.$apis.getEmailCode(params).then((res: any) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
        }
      });
    } else {
      this.$message.warning('请输入邮箱或手机号')
    }
  }
  verifyPasswordCheck(rule: any, value: string, callback: any): void {
    let password: string = this.formData.password;
    if (value === "") {
      callback(new Error("请输入密码"));
    }
    if (value && password && value.trim() !== password.trim()) {
      callback(new Error("两次密码不一致"));
    }
    callback();
  }
  // 注册
  handleOk(): void {
    this.ruleForm.validate((valid: any) => {
      if (valid) {
        let params = {
          loginId: this.formData.loginId,
          code: this.formData.code,
          password: this.formData.password,
        };
        this.$apis.resetPassword(params).then((res: any) => {
          if (res.code === "200") {
            this.$message.success("重置成功");
            this.$router.push({ path: "/user/login" });
          } else {
            this.$message.warning(res.msg);
          }
        });
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("ruleForm")
  ruleForm!: any;
  //创建前钩子函数
  created(): void {}
}
